h3{
  color: white;
  font-weight:400;
}

html{
  background-color: #222222;

}

#root{
  display: flex;
  justify-content: center;
  background-color: #222222;
}

.home-page{
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 25px;
  max-width: 1600px;
  z-index: 0;
  width: 100%;
}
h1{
  color: white;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
}
p{
  color: white;
  font-size: 16px;
}

nav{
  display: flex;
  justify-content: space-between;
}

.top-section{
  margin-top:50px;
  display:flex; 
  justify-content: space-between;
  margin-bottom: 10%;
}
.pill-button{
  color: white;
  border: white 1px solid;
  border-radius: 30px; 
  width: fit-content;
  padding: 3px 10px;
  font-size: 16px;
  margin: 0px 10px 10px 0px;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */

}
.pill-button:hover{
  cursor: pointer;
  top: -10;
}
.pill-selected{
  border-color: #FD4345;
  background-color: #FD4345;
}

.pill-selected:hover{
  color: white;
}
.intro-content{
  max-width:550px;
}
.socials {
  display: flex;
  align-items: center;
}
.profile-pic {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  margin-right: 10px;
  object-fit: cover;
  background-color: #303030;
}
.signature{
  height: 44px;
}

.linkedin{
  height: 30px;
  margin-right: 20px;
}
.github{
  height: 25px;
  margin-right: 20px;
}
.twitter{
  height: 24px;
  margin-right: 20px;
}
.spotify{
  height: 25px;
  margin-right: 20px;
}
.insta{
  height: 25px;
  margin-right: 20px;
}
.links{
  height: 25px;
  margin: 0pc;
  font-weight: 800;
}

.app-link:hover {
  cursor: pointer;
}

.filters{
  display: flex;
  flex-flow: wrap;
}

.post-card{
  margin-top: 20px;
  vertical-align: middle;
  width: 100%;
  border-radius: 10px;
}
.post-card:hover{
  cursor: pointer;
}
.posts-container{
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
  grid-auto-flow: dense; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 100vh;

  margin-bottom: 100px;
}

.line-divider{
  border-right: 1px white solid;
  margin: 0 20px 10px 10px;
}

.contact-button{
  border: none;
  color: white;
  background-color: #FD4345;
  border-radius: 30px;
  padding: 10px;
  width: 200px;
  justify-content: center;
  display: flex;
  font-weight: 500;
  text-align: center;
  font-size: 18px;
  margin-top: 1em;
}

.contact-button:hover{
  cursor: pointer;
}

.graffiti{
  max-height: 300px;
  max-width: 300px;
  /* height: 100%; */
  width: 100%;
  /* margin-right: 100px; */
}
.column{
  flex: 25%;
  padding: 0 10px;
}

footer{
  background-color: #222222;
  height: 100px;
}

@media only screen and (min-width: 768px) {
  .posts-container {
    flex-direction: row;
  }
  
}


@media only screen and (max-width: 1080px) {
  /* .graffiti{
    max-width: 200px;
    max-height: 200px;
    margin-bottom: 50px;
  } */
  .intro-content{
    max-width: 350px;

  }
}

@media only screen and (max-width: 767px) {
  .paragraph-1{
    font-size: 15px !important; 
  }
  .card-body{
    padding:20px !important
  }
  .submit-container{

    align-items: center;
    flex-direction: column;
  }
  .top-section {
    flex-direction: column;
    flex-flow: column-reverse;
    align-items: center;
    margin-bottom: 50px;
  }
  nav{
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
  }
  .intro-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
  }
  .intro-content{
    max-width: fit-content;

  }
  .home-page{
    padding-top: 10px;
  }
  .paragraph-1 img{
    width: 100% !important;
  }
  
  /* .graffiti{
    max-width: 100px;
    max-height: 100px;
    margin-bottom: 50px;
  } */
}


.background-image{
  background-image: url("./Assets/pj-gal-szabo-U8kNV-0dCS0-unsplash.jpg");
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: filter .5s,opacity 3s;
}
.background-gradient{
  position: fixed;
  background: radial-gradient(50% 50% at 50% 50%, rgba(34, 34, 34, 0.50) 0%, rgba(34, 34, 34, 0.94) 65.1%, #222222 100%);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}


.links-page-container {
  z-index: 0;
  width: 90%;
  max-width: 600px;
}

.links-page-container nav{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.links-page-container img.profile-pic{
  width: 80px;
  height: 80px;
  margin-top: 50px;
}
.link-btn{
  color: white;
  background-color: #222222;
  display: flex;
  justify-content: center;
  padding: 15px;
  border-radius: 50px;
  min-width: 250px;
  margin-top: 15px;
  width: 100%;
  font-weight: 350;
  /* border-color: white; */
  /* border-style: solid; */
  /* border-width: 1px; */
  box-shadow: rgba(255, 255, 255, 0.1) 0px 8px 24px;
}
.link-btn:hover{
  cursor: pointer;
  background-color: #FD4345;

}

.link-btn-container{
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.links-desc {
  color: white;

  display: flex;
  justify-content: center;

  font-weight: 200;
  margin-bottom: 50px;
}

.links-page-container img.links.app-link{
  position:fixed;
  width: 50px;
  height: 50px;
  bottom: 20px;
  right: 20px;
}

.contact-modal{
  background-color: #222222 !important;
  color: white !important;
  border-bottom: none !important;
}

.modal-header{
  border-bottom: none !important;
}
.modal-footer{
  border-top: none !important;
}

.btn-close{
  color: white !important;
}

.modal-header .btn-close{
  filter: invert(100%) !important;
  opacity: 1 !important;
}

.glitch-container {
  color: var(--color);
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
  display: flex;
  flex-direction: column;
}

.glitch-stack {
  display: grid;
  grid-template-columns: 1fr;
}

.glitch-stack span {
  font-weight: bold;
  grid-row-start: 1;
  grid-column-start: 1;
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
  --stack-height: calc(100% / var(--stacks) - 1px);
  --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
  --clip-top: calc(var(--stack-height) * var(--index));
  --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
  clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
  animation: stack 340ms cubic-bezier(.46,.29,0,1.24) 1 backwards calc(var(--index) * 120ms), glitch 2s ease infinite 2s alternate-reverse;
}

.glitch-stack span:nth-child(odd) { --glitch-translate: 8px; }
.glitch-stack span:nth-child(even) { --glitch-translate: -8px; }

@keyframes stack {
  0% {
    opacity: 0;
    transform: translateX(-50%);
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
  };
  60% {
    opacity: 0.5;
    transform: translateX(50%);
  }
  80% {
    transform: none;
    opacity: 1;
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }
  100% {
    text-shadow: none;
  }
}

@keyframes glitch {
  0% {
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
    transform: translate(var(--glitch-translate));
  }
  2% {
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }
  4%, 100% {  text-shadow: none; transform: none; }
}

.popup-nav{
  background-color: #FD4345;
  /* width:calc(100% - 5%); */
  border-radius: 100px;
  padding: 5px 5px;
  position: fixed;
  z-index: 10;
  top:10px ;
  right: -100%;
  display: flex;
  align-items: center;

  transition: right 1s;
  margin-left: 10px;
}

.popup-nav-open{
  right:10px;

}

html{
  scroll-behavior: smooth;
}

.hamburger{
  height: 25px;
  filter: invert(100%);
}

.nav-btn{
  color: white;
  margin-right: 10px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */

}
.nav-btn:hover{
  cursor: pointer;
}

.filter-open{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 30px;
  right:10px;
}

.filter-open .nav-btn {
  display: none;
}

.filters-container{
  display: flex;
  flex-flow: wrap;
  /* width: 100px; */
  width:auto;
  max-width: 500px;
  height:auto;
  margin-top: 10px;

}



.nav-header{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.nav-header div{
  display: flex;
  align-items: center;
}

.close-icon{
  height: 25px;
  width: 25px;
  margin-right: 10px;
  display: none;

}

.close-icon:hover{
  cursor: pointer;
}
.filter-open .close-icon{
  display: unset;
}

footer{
  display: flex;
  justify-content: center;
  align-items: center;
}

label{
  /* color: black; */
}

.form-control{
  background-color: #303030 !important;
  border-color: #303030 !important;
  color: white !important;
  
}
.form-control:focus{
  /* border-color: rgb(253, 67, 69) !important;
  box-shadow: 0 0 0 0.25rem rgb(253 67 69 / 25%) !important; */
  border-color:none !important;
  box-shadow:none !important;
}

.card{
  border: none !important;
}
.card-body{
  background-color: #303030;
}
.card-title{
  color: white;
}

.card-footer{
  background: #303030 !important;
  border-top: none !important;
  flex-wrap: wrap;
}

.pill-selected.pill-dark{
  background-color: #303030;
  border-color: #303030;
}
.smallish{
  font-size: 12px;
  padding: 0px 10px;
}

.contact-form-loading{
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;

}

.submit-container{
  display:flex; 
  justify-content:space-between;
}
/* https://stackoverflow.com/questions/30125705/css-how-to-make-an-element-fade-in-and-then-fade-out */
.elementToFadeInAndOut {
  display:flex;
  align-items:center;
  -webkit-animation: fadeinout 4s linear forwards;
  animation: fadeinout 10s linear forwards;
}

@-webkit-keyframes fadeinout {
  0%,100% { opacity: 0; }
  50% { opacity: 1; }
}

@keyframes fadeinout {
  0%,100% { opacity: 0; }
  25% { opacity: 1; }
  75% { opacity: 1; }
}





.card-title-custom{
  font-weight:800 !important;
  margin-top:25px !important; 
  font-size:25px !important;
}

.card-title-custom img{
  width: 200px !important;
}
.pill-container-custom{
  display: flex;
}
.paragraph-1{
  font-size: 25px;
  margin-top: 25px;
}

.paragraph-1 img{
  float:right; 
  width: 50%; 
  height: 50%;
  align-self:center; 
  margin-left: 50px;
  margin-bottom: 25px;
  object-fit: cover;
}

img.end-image{
  width: 20%;
  min-width: 100px;
  align-self: center;
  margin-top:30px
}

.greyout{
  width: 100%;
  position: absolute;
  /* background-color: black; */
  height: 500px;
  background-image: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,0.9));
  padding: 5%;
  font-weight: 300;
}

.paragraph-img{
  width: 100%;
  min-height: 500px;
  object-fit: cover;

}
.recaptcha-error-message {
  color: #FD4345;
  font-size: 12px;
  padding: 4px 0;
}
.recaptcha-error {
  border: 1px solid #FD4345;
  padding: 5px;
  width: fit-content;
}
.content-loading{
  width: 100% !important;
  height: fit-content;
  display: flex;
  justify-content: center;
}

.border-red{
  border-color:#FD4345 !important;
}

a{
  color: #FD4345 !important;
}
a:hover{
  color: #FD4345  !important;
}